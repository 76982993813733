@tailwind base;
@tailwind components;
@tailwind utilities;

html,
#root {
  height: 100%;
}

html.dark {
  background-color: rgb(17, 24, 39);
}

body {
  height: 100%;
}
